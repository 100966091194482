import * as React from "react";
import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	List,
	ListIcon,
	ListItem,
	Menu,
	MenuButton,
	MenuDivider,
	Progress,
	MenuItem,
	MenuList,
	Spinner,
	VStack,
	Heading,
	Text,
} from "@chakra-ui/react";


import styles from "./App.module.scss";

import { Link, NavLink, Route, Switch } from "react-router-dom";
import { Logo } from "../../components/Logo/Logo";
import { ChatbotList } from "../ChatbotList/ChatbotList";
import { Settings } from "../Settings/Settings";
import { getUserProfile } from "../../services/userServices";
import { CurrentUser, User } from "../../services/appConfig";
import { DemoChatbots } from "../DemoChatbots/DemoChatbots";
import classNames  from "classnames";
import { formatNumber } from "../../utils/commonUtils";
interface AppProps {
	onLoginOut: () => void;
}

export const App = (props: AppProps) => {
	const [userData, setUserData] = React.useState<User | null>(null);
	React.useEffect(() => {
		async function fetchData() {
			try {
				const response = await getUserProfile();
				CurrentUser.set(response.data);
				setUserData(response.data);
			} catch (error) {
				console.log("Unable to user profile", error);
			} finally {
			}
		}
		fetchData();
	}, []);

	const getUsageComponent = React.useCallback(() => {
		return null
		if(!userData) return null

		try {	


			let usage = (userData.monthUsage.weightedMsgCount * 100) / userData?.subscriptionData?.maxMessages;
			
			usage = parseFloat(usage.toFixed(2));

			let isExceed = false;
			
			if(userData?.subscriptionData?.maxMessages !== undefined) {
				isExceed = userData.monthUsage.weightedMsgCount >= userData?.subscriptionData?.maxMessages;
			}


			return <Box className={classNames(styles.usageCont, {
				[styles.usageContExceeded]: isExceed,
			})}>
				<Heading className={styles.usagePlan} size="h4" color="gray.500">{(userData?.subscriptionData?.name || '').toLowerCase().replace('app sumo', 'LTD').replace(/\b\w/g, c => c.toUpperCase())} Plan</Heading>
				<Box className={styles.usgaeNumbers}><Text as="span" fontWeight="bold">
					{formatNumber(userData.monthUsage.weightedMsgCount)}</Text> / {formatNumber(userData?.subscriptionData?.maxMessages)}</Box>
				<Text className={styles.usageLabel} fontSize="sm">Monthly usage limits</Text>
				<Progress className={styles.progressbar} w="100%" value={usage} size='sm' colorScheme={isExceed ? 'red': 'green'} borderRadius="md" />
				{
					(userData?.subscriptionData?.type !== 'LIFETIME') ? (<Box w="100%" className={styles.usageUpgradeBtn}>
					<Link to="/app/settings/subscription/">
						<Button
							w="100%"
							colorScheme="gray"
							variant="solid"
							size="sm"
						>
							Upgrade now
						</Button>
					</Link>
				</Box>) : null
				}
				
			</Box>
		} catch (error) {
			console.log("error", error);
			return null
		}

	}, [userData]);
	return (
		<VStack spacing="0" w="100%" h="100vh">
			<Flex
				shrink={0}
				shadow="base"
				w="100%"
				h="60px"
				bg="white"
				justifyContent="space-between"
				pr="10"
				pb="4"
				pt="4"
				pl="10"
				zIndex="2"
				alignItems="center"
			>
				<Logo></Logo>
				<Box>
					<Menu>
						<MenuButton>
							<Avatar size="sm" bg="teal.500" />
						</MenuButton>
						<MenuList minW="180px">
							<MenuItem fontSize="small">
								<Avatar mr={2} size="sm" bg="teal.500" /> {userData?.email}
							</MenuItem>
							<MenuDivider />
							{/* <Link to="/app/settings/subscription/">
								<MenuItem fontSize="small">Subscription</MenuItem>
							</Link> */}
							<Link to="/app/settings/general/">
								<MenuItem fontSize="small">Settings</MenuItem>
							</Link>
							<MenuDivider />
							<MenuItem onClick={props.onLoginOut} fontSize="small">
								Log Out
							</MenuItem>
						</MenuList>
					</Menu>
				</Box>
			</Flex>
			<Flex flex={1} h="calc(100% - 100px)" w="100%">
				<HStack spacing="0" w="100%" justify="start">
					<Flex
						h="100%"
						w="260px"
						shadow="base"
						bg="white"
						flexShrink={0}
						p="10"
						direction="column"
						justifyContent="space-between"
						pt="10"
					>
						<List spacing={7}>
							<ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/chat-bots">
									<Flex alignItems="center">
										<svg style={{marginRight: '10px'}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM12 17V11M9 14H15" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>

										Projects
									</Flex>
								</NavLink>
							</ListItem>
							<ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/chat-bots-demo">
									<Flex alignItems="center">
										<svg style={{marginRight: '10px'}}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 10.5L11 12.5L15.5 8M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18Z" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>

										Demo chatbots
									</Flex>
								</NavLink>
							</ListItem>
						</List>
						{getUsageComponent()}
					</Flex>
					{userData ? (
						<Switch>
							<Route path="/app/chat-bots">
								<ChatbotList />
							</Route>
							<Route path="/app/chat-bots-demo">
								<DemoChatbots />
							</Route>
							<Route path="/app/settings">
								<Settings />
							</Route>
							<ChatbotList />
						</Switch>
					) : (
						<Flex className={styles.appLoading}>
							<Spinner mr={2} /> Loading App...
						</Flex>
					)}
				</HStack>
			</Flex>
		</VStack>
	);
};
