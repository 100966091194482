import * as React from 'react';
import { Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { withRouter } from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import styles from './LegalTerms.module.scss';

const LegalTerms = () => {
  return (
    <Flex bg="white" direction="column">
      <Flex
        shrink={0}
        shadow="base"
        w="100%"
        h="60px"
        bg="white"
        pr="10"
        pb="4"
        pt="4"
        pl="10"
        zIndex="2"
        alignItems="center"
      >
        <Logo></Logo>
      </Flex>
      <Flex
        mt="20"
        mb="20"
        alignItems="center"
        justifyContent="center"
        h="100%"
      >
        <VStack spacing="16" w="50%">
          <Flex direction="column" w="100%">
            <Heading as="h1" mb="8">
              Privacy Policy
            </Heading>
            <Text>
              At Konfuzio, we are committed to protecting your privacy. This
              privacy policy explains how we collect, use, and protect your
              information when you use our chatbot service that is trained on
              data from users' websites. By using our Konfuzio chatbot, you
              agree to the terms of this privacy policy.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              Information We Collect
            </Heading>
            <Text>
              We collect public data from users' websites by crawling and
              indexing their website's content. This data is used to train our
              chatbot to provide users with more accurate and relevant
              responses. We do not classify or save any personal information
              such as names, email addresses, or phone numbers. However, we may
              collect information about users' interactions with our chatbot,
              such as chat logs, queries, and conversations.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              How We Use Your Information
            </Heading>
            <Text>
              We use the information we collect from users' websites to improve
              our chatbot's performance and enhance our service. We do not share
              any user information with third parties or use it for marketing
              purposes. The information collected is only used for the purposes
              of training our chatbot and providing our service to users.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              Data Protection
            </Heading>
            <Text>
              We take appropriate technical and organizational measures to
              protect user information from unauthorized access, alteration,
              disclosure, or destruction. We use industry-standard encryption to
              protect all communication with our servers.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              Opting Out and Deleting Data
            </Heading>
            <Text>
              Users can opt-out of our service at any time by removing the
              chatbot script from their website. We will also delete all the
              data collected from their website upon request.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              Changes to this Privacy Policy
            </Heading>
            <Text>
              Helm & Nagel GmbH, Inc reserves the right to modify this privacy
              policy at any time. If any changes are made, we will notify our
              users via email or by posting a notice on our website.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              Contact
            </Heading>
            <Text>
              If you have any questions or concerns regarding our privacy
              policy, please contact us at{' '}
              <a
                target="_blank"
                href="https://konfuzio.com/kontakt/"
                style={{ textDecoration: 'underline' }}
              >
                https://konfuzio.com/kontakt/
              </a>
              .
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20">
              Google ReCaptcha
            </Heading>
            <Text>
              We integrate the function for recognizing bots, such as in online
              form inputs ("ReCaptcha"), provided by Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA. Privacy
              Policy:{' '}
              <a
                target="_blank"
                href="https://www.google.com/policies/privacy/"
                style={{ textDecoration: 'underline' }}
              >
                https://www.google.com/policies/privacy/
              </a>
              , Opt-Out:{' '}
              <a
                target="_blank"
                href="https://adssettings.google.com/authenticated"
                style={{ textDecoration: 'underline' }}
              >
                https://adssettings.google.com/authenticated.
              </a>
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h1" mb="4">
              Terms of Use
            </Heading>
            <Text>
              By accessing or using the Konfuzio Chat App hosted under{' '}
              <a
                target="_blank"
                href="https://chat.konfuzio.com"
                style={{ textDecoration: 'underline' }}
              >
                chat.konfuzio.com
              </a>
              , you agree to be bound by the following Terms of Use. If you do
              not agree to these Terms, you may not access or use the App.
            </Text>
          </Flex>
          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20" mb="2">
              1. Use of Llama Model
            </Heading>
            <Text>
              When utilizing the Llama Model namely{' '}
              <strong>Llama-3.1-8B</strong> hosted by Konfuzio, Your data will
              be handled exclusively by Konfuzio and will be transmitted solely
              to Konfuzio servers, all of which are located entirely within
              Germany.
            </Text>
            <Text>
              Llama 3.1 is licensed under the Llama 3.1 Community License,
              Copyright © Meta Platforms, Inc. All Rights Reserved. You are
              therefore subject to the terms and conditions set forth in the{' '}
              <a
                target="_blank"
                href="https://github.com/meta-llama/llama-models/blob/main/models/llama3_1/LICENSE"
                style={{ textDecoration: 'underline' }}
              >
                Llama 3.1 Community License
              </a>
              .
            </Text>

            <Text>
              It is your responsibility to review and understand the Llama
              Community License.
            </Text>
          </Flex>

          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20" mb="2">
              2. Use of OpenAI Models
            </Heading>
            <Text mb="4">
              When you choose to use the OpenAI Models, including GPT-3.5-Turbo,
              GPT-4, or GPT-4-Turbo, within this App, your data will be
              transmitted to OpenAI's API to be processed by the selected model.
              This includes any text inputs, queries, or other data you provide
              when interacting with your Chatbot.
            </Text>
            <Heading as="h3" fontSize="18" mb="2">
              Data Transmission and Processing
            </Heading>
            <Text mb="2">
              <strong>Data Handling:</strong> The data you send to the OpenAI
              Models via the App is used solely for the purpose of generating
              responses and will be processed according to{' '}
              <a
                target="_blank"
                href="https://openai.com/privacy"
                style={{ textDecoration: 'underline' }}
              >
                OpenAI’s privacy policies
              </a>{' '}
              and terms of service. OpenAI may log and store this data to
              improve model performance, ensure compliance with usage policies,
              and for other purposes as described in their documentation.
            </Text>
            <Text mb="2">
              <strong>User Responsibility:</strong> You are responsible for
              ensuring that any data you provide to the OpenAI Models complies
              with all applicable laws, including data privacy regulations. You
              should avoid sending sensitive, personal, or confidential
              information unless you understand and accept the risks involved.
            </Text>
            <Text mb="2">
              <strong>Compliance:</strong> By using the OpenAI Models, you agree
              to adhere to{' '}
              <a
                target="_blank"
                href="https://openai.com/policies/usage-policies"
                style={{ textDecoration: 'underline' }}
              >
                OpenAI's Usage Policies
              </a>
              , which include restrictions on creating harmful content, abuse of
              the API, and other prohibited activities.
            </Text>{' '}
          </Flex>

          <Flex direction="column" w="100%">
            <Heading as="h2" fontSize="20" mb="2">
              3. Text recognition in documents and images through OCR:
            </Heading>
            <Text>
            By uploading documents to {' '}
            <a
                target="_blank"
                href="https://chat.konfuzio.com"
                style={{ textDecoration: 'underline' }}
              >
                chat.konfuzio.com
            </a>, 
            you agree that your documents will be processed using the Azure Read Document Intelligence API. 
            This API is fully hosted in the <strong>Germany West Central region</strong>, ensuring that all data processing occurs 
            within this geographic area, adhering to strict data privacy and security regulations.
            </Text>

            <Text>
            By using the Azure Read Document Intelligence API through {' '}
            <a
                target="_blank"
                href="https://chat.konfuzio.com"
                style={{ textDecoration: 'underline' }}
              >
                chat.konfuzio.com
            </a>, 
            you also agree to comply with the {' '}
            <a
                target="_blank"
                href="https://azure.microsoft.com/en-us/support/legal/"
                style={{ textDecoration: 'underline' }}
              >
                Microsoft Azure Terms of Use
            </a>. 
            </Text>
          </Flex>

          <Flex direction="column" w="100%">
            <Heading as="h1" mb="8">
              Imprint
            </Heading>
            <Text>
              Konfuzio Chat is a product of the{' '}
              <a
                target="_blank"
                href="https://helm-nagel.com/en/imprint/"
                style={{ textDecoration: 'underline' }}
              >
                Helm & Nagel GmbH
              </a>
              .
            </Text>
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default withRouter(LegalTerms);
